export default class Logo {
  constructor(element) {
    this.element = element;
    this.title = document.title;
    this.screenWidth = window.outerWidth;
    this.init();
  }
  init() {
    this.initpageverification();
  }

  initpageverification() {
    if (
      this.title === 'Accueil | Plannification MASA' &&
      this.screenWidth >= 1920
    ) {
      this.element.style.width = '220px';
      this.element.style.height = 'auto';
    } else if (
      this.title === 'Accueil | Plannification MASA' &&
      this.screenWidth <= 1920 &&
      this.screenWidth > 768
    ) {
      this.element.style.width = '150px';
      this.element.style.height = 'auto';
    } else if (this.screenWidth <= 440) {
      this.element.style.width = '72px';
      this.element.style.height = 'auto';
    } else {
      this.element.style.width = '100px';
      this.element.style.height = 'auto';
    }
  }
}
