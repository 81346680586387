export default class Modal {
  constructor(element) {
    this.element = element;
    this.modals = Array.from(document.querySelectorAll('.modal-equipe')); // Récupère toutes les modales
    this.init();
  }

  init() {
    console.log('good');
    this.modals.forEach((modal) => {
      // Récupère l'élément de fermeture de cette modal
      const closeBtn = modal.querySelector('.close');
      const modalId = modal.getAttribute('id');
      const openBtn = document.querySelector(
        `[data-modal-target="${modalId}"]`
      );
      // Vérifie si le bouton d'ouverture existe avant d'attacher un événement
      if (openBtn) {
        openBtn.onclick = () => {
          modal.style.display = 'block';
        };
      }
      // Vérifie si le bouton de fermeture existe avant d'attacher un événement
      if (closeBtn) {
        closeBtn.onclick = () => {
          modal.style.display = 'none';
        };
      }
      // Ferme la modal si l'utilisateur clique en dehors de celle-ci
      window.onclick = (event) => {
        if (event.target === modal) {
          modal.style.display = 'none';
        }
      };
    });
  }
}
